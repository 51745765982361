<script lang="tsx">
import type { SlotsType } from 'vue'

export type BaseUiSkeletonProps<Colors extends string, Variants extends string> = {
    type?: 'shine' | 'pulse'

    color?: Colors
    variant?: Variants

    /**
     * Whether to adapt the color scheme based on the user's preference.
     */
    adaptColorScheme?: boolean
}

type BaseUiSkeletonSlots<Colors extends string, Variants extends string> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiSkeleton<
    Colors extends string,
    Variants extends string = 'default',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiSkeletonProps<Colors, Variants>, BaseUiSkeletonSlots<Colors, Variants>>) {
    return defineComponent(
        (props: BaseUiSkeletonProps<Colors, Variants>, ctx) => {

            return () => (
                <div
                    class={['sim-skeleton', {
                        'animate-pulse': props.type === 'pulse',
                        'sim-skeleton--shine': props.type === 'shine',
                        'sim-skeleton--adapt': props.adaptColorScheme,
                        [`sim-skeleton--c-${props.color}`]: props.color,
                        [`sim-skeleton--v-${props.variant}`]: props.variant,
                    }]}
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {})}
                </div>
            )
        },
        {
            props: {
                type: {
                    type: String as PropType<BaseUiSkeletonProps<Colors, Variants>['type']>,
                    default: options?.props?.type?.default ?? 'pulse',
                    required: options?.props?.type?.required ?? false,
                },
                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiSkeletonProps<Colors, Variants>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    // @ts-ignore
                    type: String as PropType<BaseUiSkeletonProps<Colors, Variants>['variant']>,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                adaptColorScheme: {
                    type: Boolean as PropType<BaseUiSkeletonProps<Colors, Variants>['adaptColorScheme']>,
                    default: options?.props?.adaptColorScheme?.default ?? false,
                    required: options?.props?.adaptColorScheme?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiSkeletonSlots<Colors, Variants>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiSkeleton()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSkeleton.scss" as *;

@include skeleton {
    @include set-skeleton-color(#ddda, #333);
    @include set-skeleton-shine-color(#efefefaa, #444);
}

</style>
